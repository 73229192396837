import React from "react";
import logo from "../../assets/images/logo.webp";
import QRCode from "react-qr-code";
import { useTracking } from "../../Contexts/TrackingContext";

const ComingSoon = () => {
  const { getAllCookies, getCookie } = useTracking();

  const clickId = getCookie("click_id");
  const member = getCookie("member");

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#ffe001",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "300px",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <img alt="logo" src={logo} style={{ width: "450px" }} />
        <h2>Próximamente...</h2>

        <p>
          Escanea el código que aparece abajo con tu móvil para acceder
          completamente.
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "1rem",
            backgroundColor: "white",
            borderRadius: "2rem",
          }}
        >
          <QRCode
            size={256}
            style={{
              height: "auto",
              maxWidth: "100%",
              width: "100%",
            }}
            value={`http://www.quiniela6.mx/?click_id=${clickId}&member=${member}`}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
