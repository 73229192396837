import React from "react";

const Privacy = () => {
  return (
    <div style={{ padding: "20px" }}>
      <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
      <title />
      <meta name="generator" content="LibreOffice 7.6.4.1 (Linux)" />
      <meta name="author" content="Alberto Orozco" />
      <meta name="created" content="2023-08-03T17:37:00" />
      <meta name="changedby" content="Alberto Orozco" />
      <meta name="changed" content="2023-08-03T22:48:00" />
      <meta name="AppVersion" content={16.0} />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            '\n\t\t@page { size: 8.5in 11in; margin: 1in }\n\t\tp { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }\n\t\th1 { color: #2f5496; line-height: 108%; text-align: left; page-break-inside: avoid; orphans: 2; widows: 2; margin-bottom: 0in; direction: ltr; background: transparent; page-break-after: avoid }\n\t\th1.western { font-family: "Calibri Light", serif; font-size: 16pt }\n\t\th1.cjk { font-family: ; font-size: 16pt }\n\t\th1.ctl { font-family: "Times New Roman"; font-size: 16pt }\n\t\th2 { color: #2f5496; line-height: 108%; text-align: left; page-break-inside: avoid; orphans: 2; widows: 2; margin-top: 0.03in; margin-bottom: 0in; direction: ltr; background: transparent; page-break-after: avoid }\n\t\th2.western { font-family: "Calibri Light", serif; font-size: 13pt }\n\t\th2.cjk { font-family: ; font-size: 13pt }\n\t\th2.ctl { font-family: "Times New Roman"; font-size: 13pt }\n\t\th1.contents-heading-western { so-language: en-US }\n\t\ta:link { color: #0563c1; text-decoration: underline }\n\t',
        }}
      />
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <font face="Assistant, serif">
          <font size={6} style={{ fontSize: "28pt" }}>
            <span style={{ letterSpacing: "-0.5pt" }}>
              <b>Política de Privacidad de Quiniela 6</b>
            </span>
          </font>
        </font>
      </p>
      <p
        align="justify"
        style={{
          lineHeight: "115%",
          marginTop: "0.08in",
          marginBottom: "0.08in",
        }}
      >
        <font face="Assistant, serif">
          <font size={2} style={{ fontSize: "10pt" }}>
            <span lang="en-US">
              <b>INTRODUCCIÓN</b>
            </span>
          </font>
        </font>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Palsar Limited, incluidas sus afiliadas (colectivamente, la "
        <b>Compañía</b>", "<b>nosotros</b>", "<b>nuestro</b>" o "<b>nosotros</b>
        "), se compromete profundamente a salvaguardar las expectativas de
        privacidad de sus usuarios ("<b>Usuarios</b>", "<b>usted</b>" o "
        <b>su</b>"). En consecuencia, hemos establecido esta política de
        privacidad ("Política"), que describe nuestras prácticas de protección
        de datos, incluyendo cómo recopilamos, utilizamos, divulgamos y
        protegemos su información personal, así como sus derechos con respecto a
        su información personal.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Lea esta Política con mucho cuidado y utilícela para tomar decisiones
        bien fundamentadas, ya que su acceso y uso de nuestro servicio, ya sea
        al visitar nuestro sitio web ("<b>Sitios web</b>") o al crear una cuenta
        a través de nuestro servicio (colectivamente "<b>Servicio</b>"),
        significa que ha leído y comprendido todos los términos detallados aquí
        y acepta esta Política y la recopilación, uso, retención y divulgación
        de su información personal de acuerdo con estos términos. Si no está de
        acuerdo con los términos de esta Política, por favor, no utilice
        nuestros Servicios. No está legalmente obligado a proporcionarnos
        ninguna información personal, pero sin ella, no podremos brindarle la
        gama completa o la mejor experiencia al utilizar nuestros Servicios.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        La información personal se define como cualquier información que
        identifica a un individuo o que podría identificar razonablemente a un
        individuo con un esfuerzo razonable, incluidos, entre otros: su nombre,
        apellido, fecha de nacimiento, dirección de residencia, dirección IP,
        dirección de correo electrónico o número de teléfono, documentos de
        identificación, documentos financieros como facturas de servicios
        públicos o extractos bancarios, así como otra información no pública
        sobre usted que esté asociada o vinculada a cualquiera de lo anterior,
        en adelante referida como la "<b>Información Personal</b>". Las
        actividades de procesamiento incluyen, entre otras cosas, la
        recopilación, uso, retención y divulgación de dichos datos.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Su Información Personal será procesada por nosotros (incluidos nuestros
        agentes y/o empleados) y, cuando corresponda, por cualquier otra empresa
        de nuestro grupo de empresas y/o nuestros socios y subcontratistas, de
        acuerdo con esta Política.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Si tiene alguna pregunta sobre esta Política o la forma en que
        utilizamos su Información Personal, consulte nuestra tabla de contenido
        y la sección "¿Cómo contactarnos?" que se encuentra más adelante en este
        documento. La Compañía es el responsable del tratamiento de la
        Información Personal recopilada y es responsable del procesamiento de su
        Información Personal y tiene sus oficinas registradas en EXIT 42
        LIMITED, 57/63 LINE WALL ROAD, GX 11 1AA, GIBRALTAR.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <div id="Table of Contents1" dir="ltr">
        <div id="TextSection" dir="ltr">
          <h1 lang="en-US" className="contents-heading-western">
            Contenido
          </h1>
        </div>
        <p
          style={{
            lineHeight: "108%",
            marginLeft: "0.15in",
            marginBottom: "0.07in",
          }}
        >
          <a href="#_Toc141968312">Información Personal Recopilada 2</a>
        </p>
        <p
          style={{
            lineHeight: "108%",
            marginLeft: "0.15in",
            marginBottom: "0.07in",
          }}
        >
          <a href="#_Toc141968313">Menores de edad 3</a>
        </p>
        <p
          style={{
            lineHeight: "108%",
            marginLeft: "0.15in",
            marginBottom: "0.07in",
          }}
        >
          <a href="#_Toc141968314">
            ¿Para qué fines utilizamos la Información Personal recopilada? 4
          </a>
        </p>
        <p
          style={{
            lineHeight: "108%",
            marginLeft: "0.15in",
            marginBottom: "0.07in",
          }}
        >
          <a href="#_Toc141968315">Marketing 6</a>
        </p>
        <p
          style={{
            lineHeight: "108%",
            marginLeft: "0.15in",
            marginBottom: "0.07in",
          }}
        >
          <a href="#_Toc141968316">Divulgación de Información a Terceros 7</a>
        </p>
        <p
          style={{
            lineHeight: "108%",
            marginLeft: "0.15in",
            marginBottom: "0.07in",
          }}
        >
          <a href="#_Toc141968317">
            Transferencia Internacional de Información 8
          </a>
        </p>
        <p
          style={{
            lineHeight: "108%",
            marginLeft: "0.15in",
            marginBottom: "0.07in",
          }}
        >
          <a href="#_Toc141968318">Seguridad de la Información 8</a>
        </p>
        <p
          style={{
            lineHeight: "108%",
            marginLeft: "0.15in",
            marginBottom: "0.07in",
          }}
        >
          <a href="#_Toc141968319">Retención de Información Personal 8</a>
        </p>
        <p
          style={{
            lineHeight: "108%",
            marginLeft: "0.15in",
            marginBottom: "0.07in",
          }}
        >
          <a href="#_Toc141968320">Sus Derechos 9</a>
        </p>
        <p
          style={{
            lineHeight: "108%",
            marginLeft: "0.15in",
            marginBottom: "0.07in",
          }}
        >
          <a href="#_Toc141968321">Cambios en la Política de Privacidad 10</a>
        </p>
        <p
          style={{
            lineHeight: "108%",
            marginLeft: "0.15in",
            marginBottom: "0.07in",
          }}
        >
          <a href="#_Toc141968322">Versión de Idioma 10</a>
        </p>
        <p
          style={{
            lineHeight: "108%",
            marginLeft: "0.15in",
            marginBottom: "0.07in",
          }}
        >
          <a href="#_Toc141968323">¿Cómo contactarnos? 10</a>
        </p>
        <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
          <br />
          <br />
        </p>
      </div>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <h2 className="western">
        <a name="_Toc141968312" />
        Información Personal Recopilada
      </h2>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Recopilamos Información Personal proporcionada directamente por usted,
        relacionada con los servicios proporcionados por nuestro sitio. Algunos
        ejemplos de la Información Personal que recopilamos pueden incluir, pero
        no se limitan a, lo siguiente:
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        • Registro/Inicio de sesión: Al registrarse como titular de cuenta y
        usuario de nuestros Servicios (o al completar el formulario de
        registro), la información proporcionada se utilizará para la
        identificación del usuario y nuestra gestión de la cuenta. Esta
        información puede incluir, entre otros, los siguientes datos: su nombre,
        apellido, fecha de nacimiento, dirección de correo electrónico,
        dirección de residencia, teléfono, ocupación, negocio, profesión o
        actividad comercial, dirección IP, dispositivo, facturas de servicios
        públicos, estados de cuenta bancarios y cualquier otro dato obligatorio
        que se nos solicite en cualquier momento con fines de registro y/o uso
        continuado del servicio.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        • Chat en vivo/Soporte/Formulario de contacto: Supervisamos y/o grabamos
        sus conversaciones con nuestro departamento de atención al cliente y
        conservamos las grabaciones durante el tiempo necesario para manejar sus
        consultas de atención al cliente y/o por razones de seguridad, o según
        lo requiera la ley y/o en el mejor interés de la Compañía.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        • Procesamiento de pagos: Cualquier información proporcionada sobre
        cuentas, tarjetas de crédito u otros datos relacionados con
        transacciones de pago se recopilará y podrá ser transmitida al proveedor
        de servicios de pago para cumplir con el pago solicitado por el usuario.
        Pueden aplicarse términos y condiciones adicionales de los proveedores
        de pago, que deberían estar disponibles durante el proceso de
        transacción.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        • Documentación de identificación: Recopilamos y almacenamos documentos
        cargados, con el fin de identificar a los clientes, procesar sus
        solicitudes y/o cumplir con las leyes y regulaciones vigentes. Estos
        documentos también se utilizan con el propósito de prevención y
        detección de fraudes, y para garantizar el juego responsable.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        • Comportamiento del jugador: También procesamos información sobre el
        comportamiento del jugador para la prevención de fraudes, garantizar el
        juego responsable, mejorar la calidad del servicio y las recomendaciones
        al cliente. Procesamos estos datos en base a una obligación legal y un
        interés legítimo.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        • Información de juego: Grabamos información de juego que incluye, entre
        otras cosas, sus depósitos, apuestas, bonificaciones, duración de las
        sesiones de juego y sus puntajes más altos.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        • Información voluntaria: También recopilamos información que usted nos
        proporciona voluntariamente para personalizar su cuenta o para una
        función específica. Por ejemplo, puede proporcionar su género, edad,
        intereses y otra información adicional sobre usted que nos proporciona
        voluntariamente a su discreción para una función específica. Además,
        recopilamos su interacciones cuando responde a nuestras comunicaciones,
        nos brinda comentarios, se comunica con nosotros por correo electrónico
        o comparte información adicional sobre usted a través del uso del
        Servicio.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        • Información que recopilamos de terceros: A veces recopilamos
        Información Personal de proveedores de servicios de terceros que es
        relevante para la provisión de los servicios, así como Información
        Personal que se recopila para verificar su identidad y prevenir
        actividades fraudulentas o ilegales.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        • Historial de inicio de sesión e información técnica: Para mejorar la
        funcionalidad del servicio y brindarle una mejor experiencia de usuario,
        recopilamos información técnica transmitida por su dispositivo, incluida
        cierta información de software y hardware (por ejemplo, el tipo de
        navegador y sistema operativo que utiliza su dispositivo, preferencia de
        idioma, hora de acceso y el nombre de dominio del sitio web desde el
        cual enlazó al Servicio, etc.).
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        • Información analítica: Recopilamos información sobre su uso del
        servicio, como el uso de aplicaciones, archivos de registro, actividad
        del usuario (por ejemplo, páginas vistas, movimientos del usuario,
        tiempo empleado, navegación en línea, clics, otras acciones, etc.),
        marcas de tiempo, alertas, etc. Esta información se recopila, entre
        otras cosas, para solucionar errores y problemas, así como para fines de
        investigación y análisis sobre su uso del Servicio.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <h2 className="western">
        <a name="_Toc141968313" />
        Menores de edad
      </h2>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        El Servicio no está dirigido ni diseñado para personas menores de 18
        años o personas menores de la edad legal de consentimiento con respecto
        al uso del Servicio en cualquier jurisdicción, lo que sea mayor
        ("Mayoría de Edad Legal"). No recopilamos intencionalmente Información
        Personal de menores de edad.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Si no tiene la Mayoría de Edad Legal, queda prohibido el acceso o uso
        del Servicio, así como proporcionarnos cualquier Información Personal.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Nos reservamos el derecho de acceder y verificar cualquier Información
        Personal recopilada de usted. En caso de que tengamos conocimiento de
        que un usuario que no tenga la Mayoría de Edad Legal ha compartido
        alguna información, eliminaremos dicha información.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <h2 className="western">
        <a name="_Toc141968314" />
        ¿Para qué fines utilizamos la Información Personal recopilada?
      </h2>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <table width={624} cellPadding={7} cellSpacing={0}>
        <colgroup>
          <col width={298} />
          <col width={295} />
        </colgroup>
        <tbody>
          <tr valign="top">
            <td
              width={298}
              bgcolor="#aeaaaa"
              style={{
                background: "#aeaaaa",
                border: "1px solid #000000",
                padding: "0in 0.08in",
              }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      <b>Propósito</b>
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td
              width={295}
              bgcolor="#aeaaaa"
              style={{
                background: "#aeaaaa",
                border: "1px solid #000000",
                padding: "0in 0.08in",
              }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      <b>Base Legal</b>
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td
              width={298}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      <b>
                        Provisión de nuestro sitio web; soporte y relaciones con
                        el cliente.
                      </b>
                    </font>
                    <font face="Assistant, serif">
                      Utilizamos su Información Personal, como su nombre de
                      usuario y dirección de correo electrónico, con fines de
                      servicio al cliente y para presentarle nuestro sitio web.
                      Esto incluye, por ejemplo, administrar y actualizar su
                      cuenta, proporcionar y operar el sitio web, mantenerlo
                      informado sobre nuestras últimas actualizaciones o
                      responder a cualquier consulta, así como a su solicitud
                      para ejercer sus derechos de usuario.
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td
              width={295}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      Las bases legales para el procesamiento de estos datos son
                      el cumplimiento de nuestras obligaciones contractuales
                      hacia usted, por ejemplo, según lo descrito en esta
                      Política de Privacidad y/o Términos de Uso; su
                      consentimiento (por ejemplo, cuando acepta proporcionar
                      Información Personal al aceptar nuestra Política de
                      Cookies, Política de Privacidad y Términos de Uso); el
                      cumplimiento de nuestras obligaciones legales; y nuestros
                      intereses legítimos. Nuestros intereses legítimos en este
                      caso son hacer cumplir nuestras políticas, proteger contra
                      el fraude y el uso indebido de nuestro sitio web.
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td
              width={298}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      <b>Mejora de nuestro sitio web</b>
                    </font>
                    <font face="Assistant, serif">
                      . Recopilamos y analizamos información sobre usted y su
                      uso de nuestro sitio web para mejorar la usabilidad y
                      eficacia del mismo, incluyendo la depuración para
                      identificar y reparar errores o realizar investigaciones
                      internas para el desarrollo y demostración tecnológicos.
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td
              width={295}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      La base legal para el procesamiento de estos datos es
                      nuestro interés legítimo, en este caso, proporcionar y
                      mejorar nuestro sitio web.
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td
              width={298}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      <b>Marketing, publicidad y análisis</b>
                    </font>
                    <font face="Assistant, serif">
                      . Utilizamos su Información Personal para proporcionarle
                      anuncios personalizados, incluida publicidad conductual
                      cuando visita nuestro sitio web, para comercializar
                      nuestro sitio web y recopilar información de uso, agregada
                      para fines analíticos, estadísticos e investigativos,
                      incluida la notificación de ofertas promocionales.
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td
              width={295}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      Las bases legales para el procesamiento de estos datos son
                      su consentimiento (cuando sea necesario) y nuestros
                      intereses legítimos. Nuestros intereses legítimos en este
                      caso son brindarle servicios, contenido y anuncios
                      personalizados que se ajusten mejor a sus intereses, así
                      como promover nuestro sitio web.
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td
              width={298}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      <b>Integridad.</b>
                    </font>
                    <font face="Assistant, serif">
                      Procesamos cierta información sobre usted y su uso del
                      sitio web para mantener la integridad y seguridad del
                      mismo.
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td
              width={295}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      Las bases legales para el procesamiento de estos datos son
                      el cumplimiento de nuestras obligaciones legales y
                      nuestros intereses legítimos. Nuestros intereses legítimos
                      en este caso son mantener la integridad de nuestro sitio
                      web y la seguridad de nuestros usuarios finales.
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td
              width={298}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      <b>
                        Resolución de disputas y protección de nuestros derechos
                        legales
                      </b>
                    </font>
                    <font face="Assistant, serif">
                      . Recopilamos su Información Personal para investigar
                      violaciones de nuestras políticas, resolver disputas
                      relacionadas con su uso del sitio web y establecer y
                      defender nuestros derechos legales.
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td
              width={295}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      La base legal para el procesamiento de estos datos es
                      nuestro interés legítimo. Nuestros intereses legítimos en
                      este caso son establecer y defender nuestros derechos
                      legales.
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td
              width={298}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      <b>Pagos.</b>
                    </font>
                    <font face="Assistant, serif">
                      Recopilamos cierta información sobre sus instrumentos de
                      pago con el propósito de conectar estos a su cuenta en
                      nuestro sitio web.
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td
              width={295}
              style={{
                background: "transparent",
                border: "1px solid #000000",
                padding: "0in 0.08in",
              }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      Las bases legales para el procesamiento de estos datos son
                      el cumplimiento de nuestras obligaciones contractuales, la
                      protección de nuestros intereses legítimos, en este caso,
                      hacer cumplir nuestras políticas y prevenir el mal uso de
                      nuestro sitio web, y el cumplimiento de las obligaciones
                      legales a las que estamos sujetos.
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td
              width={298}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      <b>Transacciones corporativas.</b>
                    </font>
                    <font face="Assistant, serif">
                      Compartiremos su Información Personal con posibles
                      compradores, sucesores o inversores en la Compañía o en
                      caso de una transacción corporativa (por ejemplo, venta de
                      una parte sustancial de nuestro negocio, fusión,
                      reorganización, quiebra, consolidación o venta de un
                      activo o transferencia de su operación).
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td
              width={295}
              style={{
                background: "transparent",
                border: "1px solid #000000",
                padding: "0in 0.08in",
              }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      La base legal para el procesamiento de estos datos es
                      nuestro interés legítimo.
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td
              width={298}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      <b>
                        Prevención de fraude o violación de nuestras políticas.
                      </b>
                    </font>
                    <font face="Assistant, serif">
                      Procesamos su Información Personal para detectar y
                      prevenir actividades fraudulentas e ilegales o cualquier
                      otro tipo de actividad que ponga en peligro o afecte
                      negativamente la integridad del sitio web, incluida la
                      identificación de riesgos asociados con su actividad en
                      nuestro sitio web. También investigaremos cualquier
                      violación de nuestras políticas y las haremos cumplir.
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td
              width={295}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      La base legal para el procesamiento de estos datos es
                      nuestro interés legítimo. Nuestros intereses legítimos en
                      este caso son proteger a nuestra Compañía y a otros
                      usuarios contra el fraude.
                    </font>
                  </font>
                </font>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td
              width={298}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      <b>Cumplimiento de las leyes aplicables</b>
                    </font>
                    <font face="Assistant, serif">
                      . Procesamos su Información Personal para cumplir con
                      diversas obligaciones legales, como la legislación sobre
                      contenido para adultos (cuando corresponda), la
                      verificación de identidad (por ejemplo, sospecha de uso
                      del sitio web por parte de un menor), prevención de
                      fraudes, cumplimiento de los derechos de los sujetos de
                      datos, etc.
                    </font>
                  </font>
                </font>
              </p>
            </td>
            <td
              width={295}
              style={{ border: "1px solid #000000", padding: "0in 0.08in" }}
            >
              <p
                align="justify"
                style={{ orphans: 2, widows: 2, marginBottom: "0in" }}
              >
                <font face="Calibri, serif">
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <font face="Assistant, serif">
                      La base legal para el procesamiento de estos datos es
                      cumplir con nuestras diversas obligaciones legales y
                      nuestros intereses legítimos.
                    </font>
                  </font>
                </font>
              </p>
              <p align="justify" style={{ orphans: 2, widows: 2 }}>
                <br />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Cuando no haya dado su consentimiento (o haya retirado el mismo) para el
        procesamiento de su Información Personal de nuestra parte, continuaremos
        procesando su Información Personal: (a) cuando el procesamiento sea
        necesario para la ejecución del contrato (es decir, los Términos de Uso)
        entre usted y nosotros; y/o (b) cuando el procesamiento sea necesario
        para cumplir con una obligación legal a la que estamos sujetos; (c)
        cuando el procesamiento sea necesario para los fines de un interés
        legítimo de la Compañía y sus afiliadas (incluido el marketing).
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Categorías Especiales de Información Personal
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Nuestro procesamiento de su Información Personal también puede implicar
        categorías especiales de datos personales de conformidad con la
        legislación aplicable, para la provisión y mejora de nuestro Servicio.
        Solo procesaremos dicha información, así como la divulgaremos a
        autoridades competentes (cuerpos de aplicación de la ley), cuando sea
        estrictamente necesario y requerido para los siguientes fines (en la
        medida permitida por la legislación aplicable): (i) prevención o
        detección de un acto ilegal; (ii) prevención de deshonestidad, prácticas
        incorrectas u otra conducta gravemente inadecuada, siempre que obtener
        su consentimiento perjudique esos fines.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <h2 className="western">
        <a name="_Toc141968315" />
        Marketing
      </h2>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Utilizaremos su Información Personal, como su dirección de correo
        electrónico, recopilada por nosotros o a través de nuestros
        subcontratistas de confianza, con el propósito de proporcionarle
        materiales promocionales mediante diferentes técnicas de marketing, como
        correo electrónico directo, marketing telefónico, SMS y correo postal
        relacionados con el Servicio, así como productos, servicios, sitios web
        y aplicaciones que se relacionen con: (i) otras empresas dentro del
        grupo de la Compañía; o (ii) socios comerciales y afiliados de la
        Compañía (conjuntamente: "Afiliados de Marketing"), que creemos podrían
        interesarle.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        También compartimos y divulgamos Información Personal con nuestros
        Afiliados de Marketing con el propósito de brindarle diferentes ofertas
        de marketing que nosotros, o nuestros Afiliados de Marketing,
        consideramos relevantes para usted.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Utilizaremos su Información Personal con el propósito de proporcionarle
        materiales promocionales únicamente cuando tengamos un interés legítimo
        en hacerlo, o cuando hayamos obtenido su consentimiento afirmativo.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Usted tiene la posibilidad en cualquier momento de rechazar recibir más
        ofertas de marketing de nosotros o de nuestros socios comerciales y
        afiliados de marketing, ya sea siguiendo los enlaces insertados en los
        correos electrónicos promocionales (por ejemplo, seleccionando el enlace
        para darse de baja) o contactando a nuestro servicio al cliente. Tenga
        en cuenta que incluso si se da de baja de nuestra lista de correo de
        marketing, seguiremos enviándole actualizaciones y notificaciones
        relacionadas con el servicio.
      </p>
      <h2 className="western">
        <a name="_Toc141968316" />
        Divulgación de Información a Terceros
      </h2>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        No alquilamos, vendemos ni compartimos su Información Personal con
        terceros ("Destinatarios") excepto según se describe en esta Política.
        La Información Personal se divulgará a los Destinatarios solo en la
        medida necesaria para el propósito específico, tal como se estipula en
        esta Política.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Divulgamos Información Personal con cualquiera de los siguientes
        Destinatarios:
      </p>
      <ul>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Empresas dentro de la Compañía y otras compañías afiliadas;
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Subcontratistas y proveedores de servicios de terceros
            internacionales, así como sus subcontratistas, que incluyen, a
            título de ejemplo (pero sin limitarse a) empresas de computación en
            la nube, afiliados de marketing, servicios de verificación de
            identidad y prevención de fraudes, y otros verificadores de datos;
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Agencias de informes crediticios;
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Proveedores de servicios de pago, procesadores de pagos;
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            A cualquier tercero que preste servicios relacionados con la
            operación o promoción del Servicio;
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            A cualquier tercero que organice eventos o torneos fuera de línea en
            nombre de o en conjunto con cualquier compañía dentro de nuestro
            grupo;
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Auditores, contratistas o asesores de cualquiera de los procesos
            comerciales de la Compañía;
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            A cualquier tercero que investigue, detecte o prevenga actividades
            fraudulentas o ilegales (por ejemplo, autoridades gubernamentales,
            policía, bancos y otras organizaciones investigadoras);
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Organismos gubernamentales y reguladores, de acuerdo con las leyes y
            regulaciones aplicables;
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Organismos de adicciones al juego;
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Proveedores de juegos;
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Autoridades de licencias, organismos gubernamentales y reguladores,
            de acuerdo con las leyes y regulaciones aplicables; y
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Agencias o autoridades de aplicación de la ley, en respuesta a su
            solicitud;
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Potenciales compradores o inversores en cualquiera de las compañías
            dentro del grupo de la Compañía (si corresponde), o en caso de una
            transacción corporativa (por ejemplo, venta de una parte sustancial
            de nuestro negocio, fusión, reorganización, quiebra, consolidación o
            venta de un activo o transferencia en la operación de los mismos) en
            relación con cualquier compañía dentro de la Compañía (en tal caso,
            la compañía adquiriente o el cesionario asumirá los derechos y
            obligaciones descritos en esta Política);
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Proveedores de investigación, diagnósticos técnicos y análisis;
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Socios de marketing y publicidad, de acuerdo con nuestra política de
            marketing (ver arriba, en "Marketing"); y
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            Cuando creamos de buena fe que la divulgación es necesaria para
            proteger nuestros derechos o reclamaciones legales, hacer cumplir
            nuestras políticas (incluidos nuestros Términos de Uso y Política),
            proteger su seguridad o la seguridad de otros, así como para
            investigar o prevenir cualquier fraude, por razones de seguridad o
            para ayudarnos con cualquier otro problema técnico relacionado.
          </p>
        </li>
      </ul>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "0.25in",
          marginBottom: "0.11in",
        }}
      >
        <br />
        <br />
      </p>
      <h2 className="western">
        <a name="_Toc141968317" />
        Transferencia Internacional de Información
      </h2>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        • Dado que operamos a nivel mundial, es necesario para la prestación del
        Servicio y en la medida requerida para el propósito específico, tal como
        se estipula en esta Política, transferir su Información Personal a
        países fuera de Gibraltar, el Espacio Económico Europeo ("EEE") o el
        Reino Unido ("RU"). Las leyes de protección de datos y otras leyes de
        estos países no son tan completas como las de Gibraltar, el EEE o el RU.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        En estas circunstancias, hacemos nuestro mejor esfuerzo para garantizar
        que su Información Personal esté protegida de acuerdo con nuestra
        Política, a través de medios contractuales (como el uso de las cláusulas
        contractuales estándar y el anexo de transferencia internacional de
        datos a las cláusulas contractuales estándar de la Comisión Europea para
        transferencias internacionales de datos aprobadas por el Comisionado de
        Información de Gibraltar, disponibles aquí) u otros medios (como
        garantizar que las decisiones del Comisionado de Información del RU
        determinen que dichas jurisdicciones ofrecen un nivel adecuado de
        protección, disponibles aquí).
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Para obtener más información sobre la transferencia de su Información
        Personal fuera de Gibraltar, el EEE o el RU, nuestros datos de contacto
        están disponibles en la sección "¿Cómo contactarnos?".
      </p>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "0.25in",
          marginBottom: "0.11in",
        }}
      >
        <br />
        <br />
      </p>
      <h2 className="western">
        <a name="_Toc141968318" />
        Seguridad de la Información
      </h2>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Hemos implementado medidas de seguridad en niveles administrativos,
        técnicos y físicos para ayudar a prevenir el acceso no autorizado, uso o
        divulgación de su Información Personal. Por ejemplo, nuestro SSL (Secure
        Socket Layer) utiliza cifrado de 256 bits. Esta tecnología cifra todos
        los datos personales transferidos entre usted y la Compañía. Si bien
        buscamos proteger su información para garantizar que se mantenga
        confidencial, no podemos garantizar la seguridad de ninguna información.
        Debe ser consciente de que siempre hay cierto riesgo al transmitir
        información a través de Internet y también existe el riesgo de que otros
        puedan encontrar una manera de frustrar nuestros sistemas de seguridad.
        Tales violaciones pueden dar lugar a consecuencias como daño
        reputacional, fraude o robo de identidad. Por lo tanto, le recomendamos
        que tenga discreción con respecto a la Información Personal que elija
        divulgar. Si siente que su privacidad no fue tratada de acuerdo con
        nuestra Política, o si alguien intentó abusar de los Servicios o actuó
        de manera inapropiada, contáctenos directamente a través de nuestros
        datos de contacto disponibles a más adelante en este mismo documento.
      </p>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "0.25in",
          marginBottom: "0.11in",
        }}
      >
        <br />
        <br />
      </p>
      <h2 className="western">
        <a name="_Toc141968319" />
        Retención de Información Personal
      </h2>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Si se ha registrado con una cuenta a través de nuestro Servicio, la
        Compañía retendrá su Información Personal durante el período en que su
        cuenta esté activa. Además, la Compañía retendrá su Información Personal
        durante períodos adicionales para permitir que la Compañía cumpla con
        sus obligaciones legales según las leyes o regulaciones aplicables.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Además, la Compañía retendrá su Información Personal por períodos más
        largos, siempre que retener dicha información sea necesario únicamente
        para los intereses legítimos de la Compañía, como la prevención de
        fraudes y el mantenimiento de registros, la resolución o el ejercicio de
        reclamaciones sobre posibles disputas.
      </p>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "0.25in",
          marginBottom: "0.11in",
        }}
      >
        <br />
        <br />
      </p>
      <h2 className="western">
        <a name="_Toc141968320" />
        Sus Derechos
      </h2>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Tiene ciertos derechos con respecto a la recopilación y el procesamiento
        de Información Personal. En la medida en que estos derechos se apliquen
        y le conciernan, puede ejercerlos contactándonos a través de los datos
        de contacto disponibles en la sección "¿Cómo contactarnos?" a
        continuación y ejercer los siguientes derechos:
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        1) Derecho de acceso: Puede obtener una copia de la Información Personal
        que tiene la Compañía en cualquier momento.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        2) Derecho a la portabilidad de datos: Tiene el derecho de recibir su
        Información Personal en un formato estructurado, de uso común y legible
        por máquina, y solicitar que su Información Personal sea transmitida a
        un tercero de su elección.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        3) Derecho de rectificación: En caso de que la información que la
        Compañía tiene sobre usted sea inexacta, incompleta o esté
        desactualizada, tiene la posibilidad de solicitar la rectificación de
        dichos datos (excepto en casos en los que la información deba mantenerse
        en su formato original según las leyes y regulaciones aplicables).
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        4) Derecho de eliminación: Tiene la posibilidad de solicitar en
        cualquier momento que la Compañía elimine los datos que tiene sobre
        usted enviando un correo electrónico a la Compañía, como se describe en
        esta Política. Sin embargo, se le informa que con la eliminación de su
        Información Personal, es posible que ya no pueda utilizar el Servicio o
        que la cuenta se cierre por completo.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        5) Derecho de limitación del procesamiento, objeción al procesamiento:
        En cualquier momento, tiene el derecho de solicitar que la Compañía
        limite o deje de realizar ciertos procesos de datos, siempre que no
        exista otra base legal en la que la Compañía esté autorizada a procesar
        dichos datos.
      </p>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "0.25in",
          marginBottom: "0.11in",
        }}
      >
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        6) Derecho a retirar su consentimiento: Tiene el derecho de retirar su
        consentimiento dado en cualquier momento. Retirar el consentimiento no
        afectará la legalidad del proceso.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        7) Derecho a presentar una queja: Todas las solicitudes, quejas o
        consultas deben dirigirse a la Compañía a la siguiente dirección de
        correo electrónico: support@quiniela6.mx con el asunto "Privacidad".
        Consideraremos cualquier solicitud, queja o consulta y le
        proporcionaremos una respuesta de manera oportuna. También puede
        presentar una queja ante la autoridad relevante de protección de datos
        si no está satisfecho con la forma en que manejamos su Información
        Personal. Tomamos nuestras obligaciones en serio y pedimos que cualquier
        inquietud se presente primero a nuestra atención, para que podamos
        intentar resolverla.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Tenga en cuenta que estos derechos no son absolutos y pueden estar
        sujetos a nuestros propios intereses legítimos y requisitos
        regulatorios. Usted es bienvenido(a) a contactarnos para cualquier
        pregunta, solicitud o queja a través de nuestros datos de contacto que
        se encuentran a continuación.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Haremos un esfuerzo por responder dentro de un plazo razonable. Por
        favor, no dude en comunicarse con nosotros en cualquier momento. Si no
        está satisfecho(a) con nuestra respuesta, puede presentar una queja ante
        la autoridad supervisora competente.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Además, debe saber que si no se ha registrado y creado una cuenta en
        nuestro sitio web, es posible que no podamos responder a su solicitud,
        ya que no contamos con suficiente información para identificarlo(a).
        También podríamos rectificar, completar o eliminar información
        incompleta o inexacta en cualquier momento y a nuestra discreción, de
        acuerdo con nuestras políticas internas. Del mismo modo, le
        solicitaremos que nos proporcione información adicional para verificar
        adecuadamente su identidad en respuesta a su solicitud.
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Si tiene alguna pregunta, inquietud o desea ejercer sus derechos, por
        favor contáctenos en support@quiniela6.mx.
      </p>
      <h2 className="western">
        <a name="_Toc141968321" />
        Cambios en la Política de Privacidad
      </h2>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Nos reservamos el derecho de cambiar esta Política en cualquier momento,
        así que le recomendamos que revise esta página con frecuencia. Haremos
        esfuerzos razonables para notificarle en caso de que se realicen cambios
        sustanciales, según lo requiera la ley. Cualquier modificación,
        actualización o alteración será efectiva a partir de la publicación de
        esta Política de Privacidad en nuestras páginas web y a partir de la
        fecha de "Última Revisión", y su uso contínuo del Servicio después de la
        fecha de "Última Revisión" constituirá la aceptación y el acuerdo de
        cumplir con esos cambios.
      </p>
      <h2 className="western">
        <a name="_Toc141968322" />
        Versión de Idioma
      </h2>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Esta Política ha sido redactada en inglés, que es la versión original y
        de control de esta Política. Todas las traducciones de esta a otros
        idiomas son únicamente para conveniencia y no controlarán el significado
        o aplicación de esta Política. En caso de cualquier discrepancia entre
        los significados de las versiones traducidas de la Política y la versión
        en inglés, prevalecerá el significado de la versión en inglés.
      </p>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "0.25in",
          marginBottom: "0.11in",
        }}
      >
        <br />
        <br />
      </p>
      <h2 className="western">
        <a name="_Toc141968323" />
        ¿Cómo contactarnos?
      </h2>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        Si surge alguna inquietud con respecto a esta Política de Privacidad,
        puede contactarnos a través de support@quinela6.mx
      </p>
    </div>
  );
};

export default Privacy;
