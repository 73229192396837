import { createBrowserRouter } from "react-router-dom";
import Terms from "./Components/Terms/Terms";
import Rules from "./Components/Rules/Rules";
import Privacy from "./Components/Privacy/Privacy";
import ComingSoon from "./Components/ComingSoon/ComingSoon";
import Support from "./Components/Support/Support";
import React from "react";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ComingSoon />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/rules",
    element: <Rules />,
  },
  {
    path: "/support",
    element: <Support />,
  },
]);
