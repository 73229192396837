import {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useState,
} from "react";

const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie =
    name +
    "=" +
    encodeURIComponent(value) +
    "; expires=" +
    expires +
    "; path=/";
};

const getCookies = () => {
  return document.cookie.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = decodeURIComponent(value);
    return acc;
  }, {});
};

const getCookieValue = (name) => {
  const cookies = getCookies();
  return cookies[name];
};

// Create a context
const TrackingContext = createContext();

export const TrackingProvider = ({ children }) => {
  const [cookies, setCookies] = useState(getCookies());

  const extractAndStoreParameters = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    params.forEach((value, key) => {
      setCookie(key, value, 30);
    });
    setCookies(getCookies());
  }, []);

  useEffect(() => {
    extractAndStoreParameters();
  }, [extractAndStoreParameters]);

  const getAllCookies = useCallback(() => {
    return cookies;
  }, [cookies]);

  const getCookie = useCallback((name) => {
    return getCookieValue(name);
  }, []);

  return (
    <TrackingContext.Provider
      value={{ extractAndStoreParameters, getAllCookies, getCookie }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => useContext(TrackingContext);
