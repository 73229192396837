import React from "react";
const Terms = () => {
  return (
    <div style={{ padding: "20px" }}>
      <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
      <title />
      <meta name="generator" content="LibreOffice 7.6.4.1 (Linux)" />
      <meta name="author" content="Claudia Salinas" />
      <meta name="created" content="2023-04-28T19:13:00" />
      <meta name="changedby" content="Claudia Salinas" />
      <meta name="changed" content="2023-05-29T18:29:00" />
      <meta name="AppVersion" content={16.0} />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            '\n\t\t@page { size: 8.5in 11in; margin: 1in }\n\t\tp { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }\n\t\th1 { color: #2f5496; line-height: 108%; text-align: left; page-break-inside: avoid; orphans: 2; widows: 2; margin-bottom: 0in; direction: ltr; background: transparent; page-break-after: avoid }\n\t\th1.western { font-family: "Calibri Light", serif; font-size: 16pt }\n\t\th1.cjk { font-family: ; font-size: 16pt }\n\t\th1.ctl { font-family: ; font-size: 16pt }\n\t\ta:link { color: #0563c1; text-decoration: underline }\n\t',
        }}
      />
      <ol>
        <li>
          <h1 className="western">
            <span lang="es-MX">Términos y Condiciones</span>
          </h1>
        </li>
      </ol>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Estos términos y condiciones ("Términos y Condiciones") se aplicarán:
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          En conjunto con cualquier término y condición específico de cualquier
          promoción aplicable. En caso de que cualquier término y condición
          específico entre en conflicto con estos Términos y Condiciones, se
          aplicarán los términos específicos de la promoción correspondiente; y
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Si solicitas participar en el juego de predicciones de fútbol Quiniela
          6 ubicado en{" "}
        </span>
        <span lang="es-MX">
          <b>www.quiniela6.mx</b>
        </span>
        <span lang="es-MX">("Competencia")</span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Se considerará que has aceptado estos Términos y Condiciones y
          aceptado quedar vinculado por ellos al participar en la Competencia.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Para los fines de estos Términos y Condiciones, cualquier referencia a
          "usted", "participante(s)" o "jugador(es)" se refiere a las personas
          que hayan participado en la Competencia de acuerdo con los términos
          establecidos en la Cláusula 4 (¿Cómo participar?).
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Tome en cuenta que Apple Inc. y Alphabet Inc. no están de ninguna
          manera vinculadas o asociadas con esta Competencia o cualquier otra
          competencia/contenido llevado a cabo en las aplicaciones móviles (como
          se define a continuación).
        </span>
      </p>
      <h1 className="western">
        <span lang="es-MX">2. El Promotor</span>
      </h1>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          La Competencia es copatrocinada por Palsar Limited, con sede en 21
          Engineer Lane Gibraltar GX11 1AA, y Exit 42 Limited, con sede en 57/63
          Line Wall Road, Gibraltar, GX11 1 AA (juntos, "el Promotor").
        </span>
      </p>
      <h1 className="western">
        <span lang="es-MX">3. Elegibilidad</span>
      </h1>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Esta Competencia está abierta únicamente a personas mayores de 18 años
          residentes en la República Mexicana, excepto:
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Los empleados del Promotor y/o de cada una de sus empresas asociadas;
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Los empleados de los agentes y/o proveedores del Promotor y/o sus
          empresas asociadas que estén profesionalmente relacionados con la
          Competencia y/o su administración; o
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Los miembros de las familias o los hogares inmediatos de aquellos
          especificados en las cláusulas anteriores.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Si los participantes no cumplen con los criterios establecidos en el
          primer párrafo de este punto, su entrada no será válida y no serán
          elegibles para ganar.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          El Promotor puede requerir que los participantes proporcionen pruebas
          de edad, residencia e identidad.
        </span>
      </p>
      <h1 className="western">
        <span lang="es-MX">4. ¿Cómo participar?</span>
      </h1>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          La Competencia se llevará a cabo hasta su finalización a discreción
          exclusiva del Promotor. El Promotor también se reserva el derecho de
          suspender o modificar la Competencia en cualquier momento.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">La entrada a la Competencia es gratuita.</span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">Para participar en la Competencia, debe:</span>
      </p>
      <ul>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">Registrarse en </span>
            <span lang="es-MX">
              <b>www.quiniela6.mx </b>
            </span>
            <span lang="es-MX">
              (el "Sitio web") o en sus aplicaciones móviles relacionadas (las
              "Aplicaciones móviles") para abrir una cuenta; y
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              Predecir los resultados para los seis partidos de Quiniela 6
              identificados para la ronda correspondiente; y
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              Hacer una predicción del tiempo en que se marcará el primer gol en
              todos los partidos de una ronda (la "Predicción de Primer Gol").
            </span>
          </p>
        </li>
      </ul>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Es necesario registrarse para abrir una cuenta en el sitio web o en
          las aplicaciones móviles a su nombre. Cualquier participante que se
          registre a nombre de otra persona será descalificado. Solo se permite
          una cuenta registrada por participante. Al registrarse para una
          cuenta, autoriza al Promotor a realizar cualquier verificación de
          identidad, crédito u otras verificaciones que pueda requerir (ya sea
          para fines propios del Promotor o según lo requiera la ley aplicable o
          un organismo regulador), incluyendo (pero no limitado a) verificar
          todos los detalles que proporciona al registrarse o cambiar los
          detalles de su cuenta. En particular, el Promotor puede verificar que
          tiene 18 (dieciocho) años de edad o más y que es residente de la
          República Mexicana. Si el Promotor descubre que es menor de 18 años
          después de las verificaciones de verificación o que no ha cumplido con
          otros criterios de verificación, su cuenta se cerrará y todas las
          entradas serán descalificadas de participar en la competencia y/o
          recibir cualquier premio (como se define a continuación).
        </span>
      </p>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          El Promotor puede proporcionar la información que ha proporcionado a
          agencias de referencia crediticia autorizadas, quienes pueden
          verificar dicha información en cualquier base de datos (pública o
          privada) a la que tengan acceso y pueden mantener un registro de esa
          verificación. Las verificaciones serán para confirmar su
          identificación y para la detección y prevención de fraudes. El
          Promotor procesará la información personal que proporcione de acuerdo
          con su Política de privacidad y cookies en{" "}
        </span>
        <span lang="es-MX">
          <b>www.quiniela6.mx</b>
        </span>
        <span lang="es-MX">.</span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Si el Promotor tiene motivos razonables para creer que se han abierto
          múltiples cuentas por la misma persona o bajo el control de la misma
          persona, todas esas cuentas serán cerradas y todas las entradas de esa
          persona y/o cuentas mantenidas por esa persona serán descalificadas de
          participar en la competencia y/o recibir cualquier premio (como se
          define a continuación).
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Solo se permite una entrada por ronda por participante.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Todas las entradas deben ser recibidas por el Promotor a más tardar en
          el momento anunciado en los puntos de entrada de la Competencia ("Hora
          de cierre"). Todas las entradas recibidas después de la Hora de cierre
          serán automáticamente descalificadas.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Una vez que se haya enviado una entrada, se puede editar antes de la
          Hora de cierre a través del sitio web o de las aplicaciones móviles.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          No se pueden cambiar las predicciones después de la Hora de cierre.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          El Promotor no acepta ninguna responsabilidad por fallos de red,
          hardware o software de computadora de cualquier tipo, que puedan
          restringir o retrasar el envío o la recepción de su entrada. En ningún
          caso se le permite enviar su entrada por correo electrónico. Cualquier
          entrada enviada por correo electrónico será automáticamente
          descalificada.
        </span>
      </p>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <h1 className="western">
        <span lang="es-MX">5. La Competencia </span>
      </h1>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          <i>
            <b>Jornada</b>
          </i>
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          El Promotor seleccionará 6 partidos en cada jornada de la Liga MX para
          la Competencia. La lista de partidos y horarios de inicio para cada
          jornada se puede ver en el sitio web de Quiniela 6 en
        </span>
        <span lang="es-MX">
          <b>www.quiniela6.mx</b>
        </span>
        <span lang="es-MX">
          . Cada jornada cierra en el horario de inicio del primer partido de la
          misma.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          El jugador debe predecir el resultado correcto de los 6 partidos de la
          Quiniela 6 que haya sido especificada para la jornada correspondiente.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Además, el jugador deberá realizar la predicción del tiempo en el cual
          se marcará el “Primer Gol”, se tomará en cuenta el primer gol anotado
          únicamente en el Primer Partido dentro de los 6 que hayan sido
          especificados por el promotor para la jornada correspondiente.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          <i>
            <b>¿Cómo se puntúa?</b>
          </i>
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Los puntos se obtienen de la siguiente manera:{" "}
        </span>
      </p>
      <ul>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">Los jugadores recibirán </span>
            <span lang="es-MX">
              <u>5 puntos</u>
            </span>
            <span lang="es-MX">
              por cada resultado correcto (número de goles para cada equipo)
              predicho en un partido de Quiniela 6.{" "}
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">Los jugadores recibirán </span>
            <span lang="es-MX">
              <u>2 puntos</u>
            </span>
            <span lang="es-MX">
              por cada resultado correcto (victoria, empate o derrota para cada
              equipo) predicho en un partido de Quiniela 6.{" "}
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">Los jugadores obtendrán </span>
            <span lang="es-MX">
              <u>0 puntos</u>
            </span>
            <span lang="es-MX">
              por una predicción correcta del Primer Gol.
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">Los jugadores </span>
            <span lang="es-MX">
              <u>NO</u>
            </span>
            <span lang="es-MX">
              obtendrán puntos cuando no realicen ninguna predicción en un
              partido de Quiniela 6.
            </span>
          </p>
        </li>
      </ul>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          <i>
            <b>Desempates</b>
          </i>
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Se utilizarán predicciones de Primer Gol para determinar a los
          ganadores en caso de que varios participantes se clasifiquen para un
          premio. La predicción de Primer Gol más precisa ganará el premio en
          tal situación de desempate.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          La predicción de Primer Gol más precisa es la que está más cerca del
          tiempo real del primer gol en cualquiera de los seis partidos de la
          ronda.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Al juzgar la predicción más cercana para el tiempo de la predicción de
          Primer Gol, se considerarán igualmente los tiempos antes y después del
          tiempo real del primer gol.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          <i>
            <b>Puntuaciones de partidos y tiempos de gol</b>
          </i>
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Las puntuaciones de los partidos y el tiempo del primer gol ("Datos de
          partidos y goles") son publicados por el grupo Stats Perform ("Opta").
          Los Datos de partidos y goles publicados por Opta se utilizarán para
          determinar las posiciones finales de la liga y los ganadores de todos
          los premios (como se definen a continuación). Cualquier gol marcado en
          el tiempo de descuento se registrará como cuarenta y cinco minutos
          para la primera mitad y noventa minutos para la segunda mitad del
          partido. El tiempo de los goles marcados se registrará de la siguiente
          manera:{" "}
        </span>
      </p>
      <ul>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">0 - 59 segundos = 1er minuto,</span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            1m 00s - 1m 59s = 2º minute,
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              2m 00s – 2m 59s = 3er minuto; y así sucesivamente.{" "}
            </span>
          </p>
        </li>
      </ul>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Por ejemplo, si un jugador selecciona el minuto 6 para la predicción
          de Primer Gol, esto se aplicará cuando el primer gol se marque entre
          5m 00s - 5m 59s. Si no hay goles en ninguno de los partidos, la
          respuesta correcta se considerará como 0 o 90.
        </span>
      </p>
      <h1 className="western">
        <span lang="es-MX">6. Premios </span>
      </h1>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">Los premios de </span>
        <span lang="es-MX">
          <u>Líder de Jornada</u>
        </span>
        <span lang="es-MX">y </span>
        <span lang="es-MX">
          <u>Líder de Torneo</u>
        </span>
        <span lang="es-MX">
          , así como cualquier Premio Adicional (definidos cada uno a
          continuación) se denominan conjuntamente como "Premios" y cada uno de
          ellos es un "Premio". El o los “Premios” pueden ser en efectivo o no
          monetarios.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          No hay alternativa en efectivo para los premios no monetarios.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          A menos que se acuerde lo contrario por escrito, los Premios no son
          reembolsables ni transferibles.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          El Promotor se reserva el derecho de sustituir los Premios (o parte de
          ellos) por un premio de valor monetario equivalente o superior si es
          necesario por motivos que estén fuera de su control.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          El Promotor no será responsable de los Premios que no lleguen a los
          ganadores por motivos que estén fuera de su control razonable.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Si cualquier partido de Quiniela 6 se pospone, se abandona o no se
          completa (donde se juegan menos de 90 minutos), se considerará nulo.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          <i>Premio al Líder de Jornada</i>
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">El premio del Líder de Jornada es de </span>
        <span lang="es-MX">
          <b>$1,000,000 MXN (un millón de pesos)</b>
        </span>
        <span lang="es-MX">
          , a menos que se especifique lo contrario, y el ganador se determinará
          de la siguiente manera:{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          El Premio del Líder de Jornada se otorgará al jugador que prediga
          correctamente los resultados de los seis partidos de Quiniela 6 en la
          jornada correspondiente.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Si hay dos o más jugadores que han predicho correctamente el resultado
          de los seis partidos de Quiniela 6, el Premio del Líder de Jornada lo
          ganará el jugador cuya predicción de Primer Gol esté más cerca del
          tiempo real del primer gol.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Si dos o más jugadores han predicho correctamente el resultado de los
          seis partidos de Quiniela 6 y sus predicciones de Primer Gol son
          igualmente precisas, el Premio del Líder de Jornada se definirá de
          acuerdo con la “Marca de tiempo” (Time Stamp), es decir, la hora
          exacta en que se realizaron las predicciones.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          En caso de no haber un ganador del Premio Líder de Jornada, se
          otorgarán precios de consolación de $50,000 pesos MXN a los 5
          jugadores con mejor puntuación.
        </span>
      </p>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          <i>
            <b>Premio al Líder de Torneo</b>
          </i>
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          El premio al Líder de Torneo es de $3,000,000 MXN y el ganador se
          determinará de la siguiente manera:{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          El Premio al Líder de Torneo se otorgará al jugador que haya acumulado
          la mayor cantidad de predicciones correctas en la Quiniela 6 al final
          del Torneo de la Liga MX.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Si hay dos o más jugadores que hayan acumulado la misma cantidad de
          predicciones correctas en la Quiniela 6 al final del Torneo de la Liga
          MX, el Premio Líder de Torneo se dividirá de manera equitativa entre
          dichos jugadores.
        </span>
      </p>
      <h1 className="western">
        <span lang="es-MX">7. </span>
        <font color="#2f5496">
          <font face="Calibri Light, serif">
            <font size={4} style={{ fontSize: "16pt" }}>
              <span lang="es-MX">
                Partidos aplazados, abandonados o incompletos{" "}
              </span>
            </font>
          </font>
        </font>
      </h1>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Si solo se completan cinco partidos de Quiniela 6 durante cualquier
          jornada, el Premio al Líder de Jornada disponible esa semana se
          reducirá al 10%.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Si solo se completan cuatro partidos de Quiniela 6 durante cualquier
          jornada, el Premio al Líder de Jornada disponible esa semana se
          reducirá al 5%.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Si se completan tres o menos partidos de Quiniela 6 durante cualquier
          jornada, el Premio al Líder de Jornada de esa semana se anulará.
        </span>
      </p>
      <h1 className="western">
        <span lang="es-MX">8. Verificación de ganadores y participantes</span>
      </h1>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Los ganadores (como se determina de acuerdo con los términos
          establecidos en la Cláusula 6 (Premios)) serán notificados por correo
          electrónico o número de teléfono registrado en su cuenta el día en que
          se complete la ronda relevante, o el siguiente día hábil (un "Día
          hábil" es un día que no sea sábado, domingo o feriado público en
          México cuando los bancos están abiertos para negocios).{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Es responsabilidad exclusiva de cada jugador asegurarse de que sus
          datos de contacto estén actualizados en su cuenta.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Los ganadores deberán verificar (con pruebas satisfactorias para el
          Promotor) su:{" "}
        </span>
      </p>
      <ul>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">edad;</span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">identidad;</span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">residencia; y </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              cualquier otra información de verificación solicitada por el
              Promotor, a satisfacción del Promotor antes de que sean elegibles
              para recibir su Premio.{" "}
            </span>
          </p>
        </li>
      </ul>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Cualquier anuncio realizado con respecto a los ganadores en
          propiedades de Palsar Limited (televisión, en línea o de otra manera)
          está pendiente de verificación y no es una confirmación final.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Una lista completa de los ganadores de los Premios por nombre estará
          disponible en las secciones de resultados del sitio web de Quiniela 6
          en{" "}
        </span>
        <span lang="es-MX">
          <b>www.quiniela6.mx</b>
        </span>
        <span lang="es-MX">.</span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Si algún ganador no puede, por cualquier motivo:{" "}
        </span>
      </p>
      <ul>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">aceptar un Premio; </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              verificar su información de registro a satisfacción del Promotor;{" "}
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              ser contactado dentro de cinco días hábiles;
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              o se ha encontrado que ha incumplido estos Términos y condiciones,{" "}
            </span>
          </p>
        </li>
      </ul>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          El Promotor se reserva el derecho de descalificar a ese ganador (y su
          elegibilidad para recibir cualquier premio bajo de esta competencia) y
          otorgar el Premio correspondiente a otro participante, si corresponde.
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Los ganadores deben permitir hasta cuarenta y cinco días para el pago
          y la entrega del Premio correspondiente.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          La decisión del Promotor es definitiva y legalmente vinculante para
          todos los participantes en relación con todos los aspectos de la
          Competencia, incluida (sin limitación), la asignación de los Premios y
          no se entrará en correspondencia.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Las entradas que no cumplan en su totalidad con estos Términos y
          condiciones serán descalificadas y no serán elegibles para recibir
          ningún Premio.
        </span>
      </p>
      <h1 className="western">
        <span lang="es-MX">9. Publicidad e Información Personal</span>
      </h1>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          El Promotor utilizará los detalles personales de cada ganador y
          participante con el propósito de administrar esta Competencia.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Al optar por recibir ofertas y comunicaciones, acepta que el Promotor,
          así como cada una de sus compañías, negocios y socios comerciales,
          puedan utilizar los detalles que nos brinda para contactarlo acerca de
          productos y servicios que puedan ser de su interés. Al optar por
          permitirnos usar su perfil de cliente, incluyendo, entre otros, su
          edad, ubicación y género, su historial y patrones de navegación,
          apuestas y juegos, su uso de redes sociales y su interacción con
          nosotros.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Acepta que el Promotor, así como cada una de sus compañías, negocios y
          socios comerciales, puedan utilizar los detalles que nos brinda para
          contactarlo y proporcionarle marketing dirigido a los productos y
          servicios que puedan ser de su interés.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Puede cambiar sus preferencias de marketing directo contactándonos por
          correo electrónico a{" "}
        </span>
        <font color="#0563c1">
          <u>
            <a href="mailto:support@quinela6.mx">
              <span lang="es-MX">
                <b>support@quinela6.mx</b>
              </span>
            </a>
          </u>
        </font>
        <span lang="es-MX">
          <b></b>
        </span>
        <span lang="es-MX">
          o cambiando sus preferencias de marketing dentro de la sección Mi
          Cuenta del sitio web de Quiniela 6. También puede hacer clic en el
          enlace "cancelar suscripción" en los correos electrónicos de marketing
          directo que le enviamos para dejar de recibir correos electrónicos de
          marketing en el futuro.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Para obtener más información sobre cómo usamos su información,
          consulte nuestra Política de Privacidad y Cookies disponible{" "}
        </span>
        <span lang="es-MX">
          <b>aquí www.quiniela6.mx.</b>
        </span>
        <span lang="es-MX"> </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          La entrada y participación en la Competición constituye el permiso
          para que el Promotor y/o sus empresas asociadas, afiliadas y
          subsidiarias utilicen los nombres de usuario, predicciones y
          fotografías de usuario de los participantes con fines de publicación,
          incluyendo, entre otros, tablas de líderes y otros displays dentro del
          sitio web de Quiniela 6 o aplicaciones relacionadas.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Con el consentimiento del ganador, los detalles del ganador se
          incluirán en dicha publicidad según lo requiera razonablemente el
          Promotor.
        </span>
      </p>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <h1 className="western">
        <span lang="es-MX">10. Limitación de responsabilidad </span>
      </h1>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Salvo en el caso de muerte o lesiones personales causadas por
          negligencia del Promotor o en caso de fraude, y en la medida en que lo
          permita la ley, el Promotor y cada una de sus empresas asociadas y
          agentes excluyen cualquier responsabilidad y todos los pasivos que
          surjan de:
        </span>
      </p>
      <ul>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              cualquier aplazamiento, cancelación, demora o cambios en los
              Premios, la Competencia, otras promociones asociadas o partidos de
              fútbol relevantes que estén fuera del control razonable del
              Promotor;{" "}
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              cualquier fallo técnico o indisponibilidad del sitio web de
              Quiniela 6, aplicaciones móviles u otras plataformas relacionadas;{" "}
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              errores u omisiones obvios relacionados con la concesión de
              Premios;{" "}
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              cualquier acto u omisión de cualquier proveedor de terceros,
              incluidos, entre otros, errores relacionados con los datos de los
              partidos y las puntuaciones.{" "}
            </span>
          </p>
        </li>
      </ul>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Los términos y condiciones de cualquier proveedor de terceros se
          aplicarán al Premio cuando corresponda.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Si alguna disposición o parte de la misma de estos Términos y
          Condiciones es o se vuelve inválida, ilegal o inexigible, se
          considerará modificada en la medida mínima necesaria para que sea
          válida, legal y exigible. Si tal modificación no es posible, se
          considerará eliminada la disposición o parte de la misma
          correspondiente. Cualquier modificación o eliminación de una
          disposición o parte de la misma en virtud de esta cláusula no afectará
          la validez y exigibilidad del resto de estos términos y condiciones.{" "}
        </span>
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Estos Términos y Condiciones se interpretarán de conformidad con la
          Ley Mexicana y usted acepta que todas las reclamaciones que pueda
          presentar contra el Promotor relacionadas con su participación en esta
          Competencia estarán bajo la jurisdicción exclusiva de los tribunales
          mexicanos.
        </span>
      </p>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <h1 className="western">
        <span lang="es-MX">11. Exclusión de Responsabilidad </span>
      </h1>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Al participar en cualquier concurso o sorteo dentro de esta
          aplicación, aceptas y reconoces que Apple no es un patrocinador, ni
          está involucrado de ninguna manera en dichas actividades. Todas las
          responsabilidades relacionadas con los concursos o sorteos son
          responsabilidad exclusiva de Quiniela 6. Además, comprendes que
          cualquier pregunta, comentario o queja relacionada con los concursos o
          sorteos deben dirigirse directamente a Quiniela 6, y no a Apple.
        </span>
      </p>
    </div>
  );
};

export default Terms;
