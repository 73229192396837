import React from "react";

const Rules = () => {
  return (
    <div style={{ padding: "20px" }}>
      <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
      <title />
      <meta name="generator" content="LibreOffice 7.6.4.1 (Linux)" />
      <meta name="author" content="Claudia Salinas" />
      <meta name="created" content="2023-05-29T18:39:00" />
      <meta name="changedby" content="Alberto Orozco" />
      <meta name="changed" content="2023-05-29T22:43:00" />
      <meta name="AppVersion" content={16.0} />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            '\n\t\t@page { size: 8.5in 11in; margin: 1in }\n\t\tp { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }\n\t\th1 { color: #2f5496; line-height: 108%; text-align: left; page-break-inside: avoid; orphans: 2; widows: 2; margin-bottom: 0in; direction: ltr; background: transparent; page-break-after: avoid }\n\t\th1.western { font-family: "Calibri Light", serif; font-size: 16pt }\n\t\th1.cjk { font-family: ; font-size: 16pt }\n\t\th1.ctl { font-family: ; font-size: 16pt }\n\t\th2 { color: #2f5496; line-height: 108%; text-align: left; page-break-inside: avoid; orphans: 2; widows: 2; margin-top: 0.03in; margin-bottom: 0in; direction: ltr; background: transparent; page-break-after: avoid }\n\t\th2.western { font-family: "Calibri Light", serif; font-size: 13pt }\n\t\th2.cjk { font-family: ; font-size: 13pt }\n\t\th2.ctl { font-family: ; font-size: 13pt }\n\t',
        }}
      />
      <p
        style={{
          letterSpacing: "-0.5pt",
          lineHeight: "100%",
          marginBottom: "0in",
        }}
      >
        <font face="Calibri Light, serif">
          <font size={6} style={{ fontSize: "28pt" }}>
            <span lang="es-MX">Reglas de Apuesta Quiniela 6</span>
          </font>
        </font>
      </p>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <p
        style={{
          lineHeight: "108%",
          textIndent: "0.5in",
          marginBottom: "0.11in",
        }}
      >
        <span lang="es-MX">
          El jugador debe predecir el resultado correcto para los seis partidos
          de Quiniela 6 que hayan sido especificados para la jornada
          correspondiente.
        </span>
      </p>
      <p
        style={{
          lineHeight: "108%",
          textIndent: "0.5in",
          marginBottom: "0.11in",
        }}
      >
        <span lang="es-MX">
          El jugador deberá realizar la predicción del tiempo en el cual se
          marcará el “Primer Gol”, se tomará en cuenta el primer gol anotado
          únicamente en el Primer Partido dentro de los 6 que hayan sido
          especificados por el promotor para la jornada correspondiente.
        </span>
      </p>
      <h1 className="western">
        <span lang="es-MX">Obtención de Puntos</span>
      </h1>
      <h1 className="western">
        <span lang="es-MX">La Competencia</span>
      </h1>
      <h2 className="western">
        <span lang="es-MX">Puntuación:</span>
      </h2>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "1in",
          marginBottom: "0.11in",
        }}
      >
        <font size={4} style={{ fontSize: "14pt" }}>
          <span lang="es-MX">
            <b>5 Puntos: </b>
          </span>
        </font>
        <font size={3} style={{ fontSize: "12pt" }}>
          <span lang="es-MX">
            <b>Resultado y Marcador Correcto </b>
          </span>
        </font>
        <span lang="es-MX">(número de goles para cada equipo) </span>
        <font size={4} style={{ fontSize: "14pt" }}>
          <span lang="es-MX">
            <b></b>
          </span>
        </font>
      </p>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "1in",
          marginBottom: "0.11in",
        }}
      >
        <font size={4} style={{ fontSize: "14pt" }}>
          <span lang="es-MX">
            <b>2 Puntos: </b>
          </span>
        </font>
        <font size={3} style={{ fontSize: "12pt" }}>
          <span lang="es-MX">
            <b>Resultado Correcto </b>
          </span>
        </font>
        <span lang="es-MX">(victoria, empate o derrota para cada equipo)</span>
        <font size={4} style={{ fontSize: "14pt" }}>
          <span lang="es-MX">
            <b></b>
          </span>
        </font>
      </p>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "1in",
          marginBottom: "0.11in",
        }}
      >
        <font size={4} style={{ fontSize: "14pt" }}>
          <span lang="es-MX">
            <b>0 Puntos: </b>
          </span>
        </font>
        <font size={3} style={{ fontSize: "12pt" }}>
          <span lang="es-MX">
            <b>Partidos sin Predicción Colocada</b>
          </span>
        </font>
      </p>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "1in",
          marginBottom: "0.11in",
        }}
      >
        <font size={4} style={{ fontSize: "14pt" }}>
          <span lang="es-MX">
            <b>0 Puntos: </b>
          </span>
        </font>
        <font size={3} style={{ fontSize: "12pt" }}>
          <span lang="es-MX">
            <b>“Predicción Primer Gol”</b>
          </span>
        </font>
      </p>
      <p
        lang="es-MX"
        style={{
          lineHeight: "108%",
          marginLeft: "1in",
          marginBottom: "0.11in",
        }}
      >
        <br />
        <br />
      </p>
      <h2 className="western">
        <span lang="es-MX">Desempate:</span>
      </h2>
      <ul>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <a name="_Hlk134096782" />
            <span lang="es-MX">
              Se utilizarán predicciones de Primer Gol para determinar a los
              ganadores en caso de que varios participantes se clasifiquen para
              un premio.
            </span>
            <font size={4} style={{ fontSize: "14pt" }}>
              <span lang="es-MX">
                <b></b>
              </span>
            </font>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              La predicción de Primer Gol más precisa ganará el premio en tal
              situación de desempate.{" "}
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              Al juzgar la predicción más cercana para el tiempo de la
              predicción de Primer Gol, se considerarán igualmente los tiempos
              antes y después del tiempo real del primer gol.
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              En caso de que dos o más usuarios continúen empatados posterior a
              haber aplicado la cláusula de desempate, el monto se dividirá en
              partes iguales entre los ganadores.
            </span>
          </p>
        </li>
      </ul>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <h2 className="western">
        <span lang="es-MX">Puntuaciones de Partidos y Tiempos de Gol:</span>
      </h2>
      <ul>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              Las puntuaciones de los partidos y el tiempo del primer gol
              ("Datos de partidos y goles") son publicados por el proveedor
              oficial
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              Los Datos de partidos y goles publicados por el{" "}
            </span>
            <font color="#ff0000">
              <span lang="es-MX">proveedor oficial de estadística </span>
            </font>
            <span lang="es-MX">
              se utilizarán para determinar las posiciones finales de la liga y
              los ganadores de todos los premios (como se definen a
              continuación).
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              Cualquier gol marcado en el tiempo de compensación se registrará
              como cuarenta y cinco minutos para la primera mitad del partido.
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              Cualquier gol marcado en el tiempo de compensación se registrará
              como 90 minutos para la segunda mitad del partido.
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              El tiempo de los goles marcados se registrará de la siguiente
              manera:{" "}
            </span>
          </p>
        </li>
      </ul>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "2in",
          marginBottom: "0.11in",
        }}
      >
        <span lang="es-MX">0 - 59 segundos = 1er minuto;</span>
      </p>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "2in",
          marginBottom: "0.11in",
        }}
      >
        <span lang="es-MX">1m 00s - 1m 59s = 2º minuto;</span>
      </p>
      <p
        style={{
          lineHeight: "108%",
          marginLeft: "2in",
          marginBottom: "0.11in",
        }}
      >
        <span lang="es-MX">
          2m 00s - 2m 59s = 3er minuto; y así sucesivamente.
        </span>
      </p>
      <p
        lang="es-MX"
        style={{
          lineHeight: "108%",
          marginLeft: "2in",
          marginBottom: "0.11in",
        }}
      >
        <br />
        <br />
      </p>
      <ul>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <font size={3} style={{ fontSize: "12pt" }}>
              <span lang="es-MX">
                <b>Ejemplo</b>
              </span>
            </font>
            <span lang="es-MX">
              <b>:</b>
            </span>
            <span lang="es-MX">
              si un jugador selecciona el minuto 6 para la predicción de Primer
              Gol, esto se aplicará cuando el primer gol se marque entre 5m 00s
              - 5m 59s.{" "}
            </span>
          </p>
        </li>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              Si no hay goles en ninguno de los partidos, la respuesta correcta
              se considerará como 0 o 90.
            </span>
          </p>
        </li>
      </ul>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <h1 className="western" align="justify">
        <span lang="es-MX">¿Cuándo debo enviar mis predicciones?</span>
      </h1>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Toda predicción deberá ser colocada antes del inicio del primero de
          los 6 juegos seleccionados por el promotor para la Jornada
          Correspondiente.
        </span>
      </p>
      <h1 className="western" align="justify">
        <span lang="es-MX">¿Puedo cambiar mis selecciones?</span>
      </h1>
      <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <span lang="es-MX">
          Una vez que hayas enviado tus selecciones, podrás realizar
          modificaciones en estas a través de la aplicación, hasta antes del
          inicio del primero de los 6 juegos seleccionados por el promotor para
          la Jornada Correspondiente.
        </span>
      </p>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
      <h1 className="western">
        <font color="#2f5496">
          <font face="Calibri Light, serif">
            <font size={4} style={{ fontSize: "16pt" }}>
              <span lang="es-MX">
                ¿Qué sucede si un Partido de Quiniela 6 es: aplazado, abandonado
                o incompleto?
              </span>
            </font>
          </font>
        </font>
      </h1>
      <ul>
        <li>
          <p style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
            <span lang="es-MX">
              Si se pospone, se abandona o no se completa (es decir, se juega
              menos de 90 minutos) cualquier partido de Quiniela 6, el partido
              se considerará nulo{" "}
            </span>
            <font size={2} style={{ fontSize: "10pt" }}>
              <span lang="es-MX">(ver</span>
            </font>
            <font size={2} style={{ fontSize: "9pt" }}>
              <span lang="es-MX">
                Términos y condiciones Generales de Quiniela 6 para efectos de
                premiación).
              </span>
            </font>
            <font size={2} style={{ fontSize: "10pt" }}>
              <span lang="es-MX"></span>
            </font>
          </p>
        </li>
      </ul>
      <p lang="es-MX" style={{ lineHeight: "108%", marginBottom: "0.11in" }}>
        <br />
        <br />
      </p>
    </div>
  );
};

export default Rules;
