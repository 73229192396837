import React, { useState } from "react";
import logo from "../../images/logo.png";
import "./support.css";
const Support = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "sans-serif",
        backgroundColor: "#93b8f5",
      }}
    >
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
          borderRadius: "15px",
          border: "2px solid #003277",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "300px",
          }}
        >
          <img
            alt="logo"
            src={logo}
            style={{ borderRadius: "20px", width: "50px", height: "50px" }}
          />
          <h1>Contect us</h1>
        </div>
        <div
          style={{
            height: "2px",
            width: "100%",
            backgroundColor: "#003277",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            width: "90%",
            borderBottom: "1px",
          }}
        >
          <h4>Full name</h4>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              width: "90%",
              height: "20px",
              borderRadius: "10px",
              paddingLeft: "5px",
              fontFamily: "sans-serif",
            }}
          />
          <h4>Email address</h4>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            name="Email"
            style={{
              width: "90%",
              height: "20px",
              borderRadius: "10px",
              paddingLeft: "5px",
              fontFamily: "sans-serif",
            }}
          />
          <h4>Title</h4>
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            style={{
              width: "90%",
              height: "20px",
              borderRadius: "10px",
              paddingLeft: "5px",
              fontFamily: "sans-serif",
            }}
          />
          <h4>Content</h4>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            type="text"
            style={{
              width: "90%",
              height: "90px",
              borderRadius: "10px",
              paddingLeft: "5px",
              fontFamily: "sans-serif",
            }}
          />
        </div>
        <a
          href={
            "mailto:support@quiniela6.mx?subject=" +
            title +
            "&cc=" +
            email +
            "&body=" +
            "Name: " +
            name +
            "%0A" +
            "Email: " +
            email +
            "%0A--------------------------%0A%0A" +
            encodeURIComponent(content)
          }
          style={{
            width: "80%",
            height: "30px",
            fontWeight: "bold",
            borderRadius: "20px",
            marginTop: "10px",
            marginBottom: "10px",
            textDecoration: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#003277",
            border: "solid 2px #003277",
          }}
        >
          Submit
        </a>
      </div>
    </div>
  );
};

export default Support;
